import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	root: {},
	top: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		margin: "0 auto",
		maxWidth: 1064,
		rowGap: theme.spacing(4),
		[theme.breakpoints.up("md")]: {
			display: "grid",
			gridTemplateColumns: "140px 1fr 140px",
			"& h2": {
				gridColumn: "2 / 3"
			},
			"& button": {
				gridColumn: "3 / 4"
			}
		}
	},
	headline: {
		paddingBottom: theme.spacing(2),
		position: "relative",
		textAlign: "center",
		"&:after": {
			background: "linear-gradient(270deg, #C65470 0%, #548EB5 100%)",
			bottom: 0,
			content: "''",
			height: 2,
			marginLeft: -28,
			left: "50%",
			position: "absolute",
			width: 56
		}
	},
	buttons: {
		alignItems: "center",
		columnGap: theme.spacing(2),
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(5),
		rowGap: theme.spacing(1),
		[theme.breakpoints.up("md")]: {
			columnGap: theme.spacing(3),
			marginTop: theme.spacing(7)
		},
		"& > .mco-specifications-top__buttons-button": {
			borderBottom: "1px solid transparent",
			cursor: "pointer",
			padding: theme.spacing(1),
			transition: "color 250ms ease"
		},
		"& > .mco-specifications-top__buttons-button:hover": {
			color: theme.palette.primary.main
		},
		"& > .mco-specifications-top__buttons-button--active": {
			borderBottom: "1px solid " + theme.palette.primary.main,
			color: theme.palette.primary.main
		}
	},
	content: {
		padding: "0 !important",
		[theme.breakpoints.up(1113)]: {
			padding: "0 24px !important"
		}
	},
	tableWrapper: {
		marginBottom: theme.spacing(4),
		"& .mco-table-01__headline": {
			display: "none"
		},
		"& .mco-table-01__cell": {
			background: "none !important"
		},
		"&:last-child": {
			marginBottom: 0
		}
	}
}));

export default styles;
