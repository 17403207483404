import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import UiContext from "@UiContext";
import PageContext from "@PageContext";
import useLink from "@micado-digital/react-link/hooks/useLink";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";

import styles from "./Search.styles";

const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const SearchOverlay = loadable(() => import("@micado-digital/react-search/SearchOverlay"));

const Search = () => {
	const [ui, setUi] = useContext(UiContext);
	const { searchOpen } = ui;
	const [pageData] = useContext(PageContext);
	const { lang } = pageData;
	const navigate = useLink();
	const sysLink = useSystemLinks();
	const css = styles();

	const searchUrl = sysLink("search");

	const handleClick = () => {
		setUi(prevUi => ({ ...prevUi, searchOpen: true }));
	};

	const handleClose = () => {
		setUi(prevUi => ({ ...prevUi, searchOpen: false }));
	};

	const handleSearch = value => {
		navigate(`${searchUrl}?q=${value}`);
	};

	return (
		<>
			<div
				className={clsx(css.search, "mco-view-component-header-search", {
					[css.searchOpen]: searchOpen,
					"mco-view-component-header-search--open": searchOpen
				})}
				onClick={handleClick}
			>
				<ReactSVGIcon src="/img/icons/search.svg" />
			</div>
			{searchOpen && (
				<SearchOverlay
					lang={lang}
					setOpen={handleClose}
					SearchFieldProps={{
						InputProps: {
							autoFocus: true,
							endAdornment: <ReactSVGIcon size={24} src="/img/icons/search.svg" />
						},
						onSearch: value => {
							handleSearch(value);
						}
					}}
				/>
			)}
		</>
	);
};

export default Search;
