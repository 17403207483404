import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(4),
		height: "100%",
		overflow: "auto",
		padding: "200px 0 0"
	},
	item: {
		margin: theme.spacing(0, 2)
	},
	link: {
		alignItems: "center",
		color: theme.palette.text.primary,
		display: "flex",
		justifyContent: "space-between"
	},
	linkSelected: {
		color: theme.palette.primary.main
	}
}));

export default styles;
