import makeStyles from "@material-ui/styles/makeStyles";

const styles = ({ headerHeight }) => {
	return makeStyles(theme => ({
		animationWrapper: {
			position: "relative",
			"& .mco-view-component-header-scroll-bottom": {
				bottom: 64,
				padding: theme.spacing(2, 0),
				position: "absolute",
				right: -48,
				zIndex: 2
			}
		},
		animation: {
			height: "65vh",
			minHeight: 300,
			maxHeight: 650,
			position: "relative",
			[theme.breakpoints.up("lg")]: {
				maxHeight: 800,
				minHeight: 500
			},
			"& .mco-view-component-header-cta": {
				position: "fixed",
				right: 0,
				top: 72,
				transform: "rotate(-90deg) translate(56px, 0)",
				transformOrigin: "right bottom",
				transition: "all 300ms ease-in",
				zIndex: theme.zIndex.appBar - 1,
				"& button": {
					borderRadius: `${theme.shape.borderRadius}px 0 0 0`
				}
			},
			"& .mco-animation-01__bullets": {
				[theme.breakpoints.up("md")]: {
					bottom: "32px !important"
				}
			},
			"& .mco-animation-01__bullet": {
				background: "#FFFFFF !important",
				borderRadius: "unset !important",
				height: "2px !important",
				opacity: "0.3 !important",
				transition: "opacity 250ms ease, width 250ms ease",
				width: "16px !important"
			},
			"& .mco-animation-01__bullet--selected": {
				opacity: 1 + " !important",
				width: "32px !important"
			},
			"& .mco-animation-01__arrow-next, .mco-animation-01__arrow-prev": {
				alignItems: "center",
				display: "flex",
				height: 40,
				left: 0,
				justifyContent: "center",
				width: 40,
				[theme.breakpoints.up("md")]: {
					height: 64,
					width: 64
				}
			},
			"& .mco-animation-01__arrow-next": {
				left: "auto",
				right: 0
			}
		},
		animationIntro: {
			height: "calc(var(--vh, 1vh) * 100)",
			maxHeight: "none !important",
			"&:after": {
				background:
					"linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%)",
				bottom: 0,
				content: "''",
				display: "block",
				left: 0,
				position: "absolute",
				right: 0,
				top: 0,
				zIndex: 1
			},
			[theme.breakpoints.up("md")]: {
				"& .mco-view-component-header-cta": {
					top: 160
				}
			}
		},
		animationScrolling: {
			[theme.breakpoints.up("md")]: {
				"& .mco-view-component-header-cta": {
					top: 72
				}
			}
		},
		animationTexts: {
			[theme.breakpoints.up("md")]: {
				alignItems: "flex-start",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				bottom: 100,
				left: 0,
				right: 0,
				margin: "0 auto",
				padding: theme.spacing(3),
				maxWidth: 1328,
				position: "absolute",
				zIndex: 1
			},
			[theme.breakpoints.up(1440)]: {
				bottom: 176
			}
		},
		animatioTextsContent: {
			[theme.breakpoints.up("md")]: {
				color: theme.palette.common.white,
				maxWidth: 632,
				"& .text-1": {
					marginTop: theme.spacing(4)
				}
			}
		},
		animationIntroMobile: {
			height: `calc((var(--vh, 1vh) * 50) - ${headerHeight / 2}px)`
		},
		animationIntroMobileTexts: {
			background: theme.palette.grey[800],
			color: theme.palette.common.white,
			minHeight: `calc((var(--vh, 1vh) * 50) - ${headerHeight / 2}px)`,
			padding: theme.spacing(3, 4),
			position: "relative",
			[theme.breakpoints.up(375)]: {
				padding: theme.spacing(3, 5)
			},
			"& .text-0": {
				paddingBottom: theme.spacing(2),
				position: "relative",
				"&:after": {
					background: "linear-gradient(270deg, #C65470 0%, #548EB5 100%)",
					bottom: 0,
					content: "''",
					height: 2,
					left: 0,
					position: "absolute",
					width: 56
				}
			},
			"& .text-1": {
				marginTop: theme.spacing(2)
			}
		}
	}))();
};

export default styles;
