import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	mobileMenu: {
		background: theme.palette.background.menu,
		bottom: 0,
		left: 0,
		padding: "0 0 100px",
		position: "fixed",
		right: 0,
		top: 0,
		"& .mco-view-component-footer-social-icons": {
			bottom: theme.spacing(3),
			justifyContent: "center",
			left: 0,
			position: "absolute",
			right: 0
		}
	}
}));

export default styles;
