import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		display: "flex",
		gap: theme.spacing(2),
		padding: theme.spacing(0, 2, 0, 0),
		userSelect: "none",
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(3),
			paddingRight: theme.spacing(5.5)
		}
	},
	link: {
		alignItems: "center",
		color: theme.palette.grey[800],
		display: "flex",
		justifyContent: "space-between",
		position: "relative",
		"&::after": {
			background: theme.palette.grey[800],
			bottom: -12,
			content: "''",
			display: "block",
			height: 1,
			left: 0,
			position: "absolute",
			transition: "width 0.5s ease",
			width: 0
		},
		"&:hover": {
			color: theme.palette.primary.main
		},
		"&:hover::after": {
			background: theme.palette.primary.main,
			width: "100%"
		}
	},
	linkSelected: {
		color: theme.palette.primary.main,
		"&::after": {
			background: theme.palette.primary.main,
			width: "100%"
		}
	},
	linkIntro: {
		color: theme.palette.common.white + " !important",
		"&::after": {
			background: theme.palette.common.white + " !important"
		}
	},
	line: {}
}));

export default styles;
