import React, { useContext } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";
import WebProfileContext from "@WebProfileContext";

import styles from "./SocialIcons.styles";

const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));

const SocialIcons = () => {
	const [profileData] = useContext(WebProfileContext);
	const { links } = profileData;
	const css = styles();

	return (
		<ul className={clsx(css.socialIcons, "mco-view-component-footer-social-icons")}>
			{links?.map((item, counter) => {
				const { type, title, url } = item;

				return <Item key={counter} type={type} title={title} url={url} />;
			})}
		</ul>
	);
};

const Item = ({ type, title, url }) => {
	const css = styles();

	return (
		<li className={clsx(css.item, "mco-view-component-footer-social-icons__item")}>
			<ReactLink rel="noopener noreferrer" target="_blank" title={title} to={url}>
				<ReactSVGIcon
					className={clsx(css.icon, "mco-view-component-social-icons__icon")}
					size={24}
					src={`/img/icons/${type}.svg`}
				/>
			</ReactLink>
		</li>
	);
};

export default SocialIcons;
