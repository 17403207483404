import makeStyles from "@material-ui/styles/makeStyles";

const styles = () => {
	return makeStyles(theme => ({
		elements: {
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(10),
			scrollMarginTop: "72px",
			paddingBottom: theme.spacing(10),
			position: "relative",
			zIndex: 1,
			"& > *:first-child:not(.mco-collapsing, .mco-view-component-breadcrumb)": {
				marginTop: theme.spacing(10.5)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(7)
			},
			[theme.breakpoints.up("md")]: {
				"& > *:last-child:not(.mco-collapsing)": {
					marginBottom: theme.spacing(10.5)
				}
			}
		},
		elementsIframe: {
			paddingBottom: 0,
			"& > *:first-child:not(.mco-collapsing, .mco-view-component-breadcrumb)": {
				marginTop: 0
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: 0
			}
		}
	}))();
};

export default styles;
