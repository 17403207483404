import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import checkIFrame from "../../utils/checkIFrame";
import groupFollowingElementsByTag from "@micado-digital/react-ui/utils/groupFollowingElementsByTag";
import useSetting from "@micado-digital/react-ui/hooks/useSettings";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import getJSXElement from "./getJSXElement";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@localDE";
import localEN from "@localEN";
import styles from "./Elements.styles";

const Breadcrumb = loadable(() => import("@Components/views/Breadcrumb"));

const Elements = ({ parentElements }) => {
	const headerSettings = useSetting({
		identifier: "header",
		layout: true,
		layoutScroll: true,
		breakpoints: true
	});
	const { height: headerHeight } = headerSettings;
	const css = styles({ headerHeight });
	const [pageData] = useContext(PageContext);
	let { accountDetailID, authorID, editDate, elements, homepage, id, lang, layoutVariant } =
		pageData;
	const blogData = {
		accountDetailID: accountDetailID,
		authorID: authorID,
		editDate: editDate,
		id: id
	};
	const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
	const sysLink = useSystemLinks();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	let parsedElements = parentElements ? parentElements : elements;

	// group downloads

	parsedElements = groupFollowingElementsByTag(
		parsedElements,
		"basic-download-group",
		"basic-download"
	);

	// group dropdowns

	parsedElements = groupFollowingElementsByTag(
		parsedElements,
		"basic-dropdown-group",
		["basic-dropdown", "*"],
		"basic-dropdown",
		"basic-dropdown"
	);

	parsedElements = groupFollowingElementsByTag(
		parsedElements,
		"extension-background-group",
		["extension-background", "*"],
		"extension-background",
		"extension-background"
	);

	// exclude elements

	const excludeElementTags = ["basic-animation-textbox"];

	parsedElements = parsedElements.filter(element => {
		return excludeElementTags.indexOf(element.tag) === -1;
	});

	const isIframe = checkIFrame();

	return (
		<div className={clsx(css.elements, "mco-elements", { [css.elementsIframe]: isIframe })}>
			{!isIframe && !homepage && layoutVariant !== "store-locator" && <Breadcrumb />}
			{parsedElements?.map(element => {
				return getJSXElement(element, lang, l, isMobile, sysLink, blogData);
			})}
		</div>
	);
};

export default Elements;
