/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import UiContext from "@UiContext";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";
import Locals from "@micado-digital/react-ui/components/Locals";
import checkIFrame from "../utils/checkIFrame";
import useIFramePostHeight from "../hooks/useIFramePostHeight";
import useIsCurrentLocation from "@micado-digital/react-ui/hooks/useIsCurrentLocation";
import useTheme from "@material-ui/core/styles/useTheme";
import Animation from "@Components/views/Animation";
import Header from "@Components/views/Header";
import Default from "./Default";
import styles from "./Views.styles";

const Footer = loadable(() => import("@Components/views/Footer"));
const ReactMetaData01 = loadable(() =>
	import("@micado-digital/react-metadata/ReactMetadata01")
);
const Tracking = loadable(() => import("./Tracking"));

const Views = () => {
	const [pageData] = useContext(PageContext);
	const { authorID, lang, layoutTag, layoutVariant, meta = {} } = pageData;
	const [ui, setUi] = useContext(UiContext);
	const { _location } = ui;
	const theme = useTheme();
	const currentLocation = useIsCurrentLocation();
	const css = styles();
	const {
		palette: {
			primary: { light: primaryMainColor }
		}
	} = theme;
	const hasAnimation = layoutVariant !== "no-animation" && layoutVariant !== "store-locator";

	useIFramePostHeight();

	useEffect(() => {
		const hash = document.URL.split("#")[1];

		if (hash) {
			setTimeout(() => {
				const $target = document.querySelector(`[data-anchor="${hash}"]`);

				if ($target) {
					$target.scrollIntoView({ behavior: "smooth", block: "start" });
				}
			}, 100);
		}

		setUi(prevState => ({
			...prevState,
			desktopMenuOpen: false,
			desktopCurrentLevel1ID: null,
			mobileMenuOpen: false,
			searchOpen: false
		}));
	}, [_location]);

	if (!lang) return null;

	const {
		canonical: metaCanonical,
		description: metaDescription,
		indexable: metaIndexable,
		image: metaImage,
		keywords: metaKeywords,
		lat: metaLat,
		links: metaLinks,
		lng: metaLng,
		title: metaTitle
	} = meta;

	const isIframe = checkIFrame();
	const isLandingpage = layoutVariant === "landingpage";

	return (
		<WebProfile>
			<AccountData authorID={authorID}>
				<Locals url={`/locals/${lang || "de"}.json`}>
					<div
						className={clsx(css.page, "mco-page", {
							[css.pageIntro]: layoutVariant === "intro",
							"mco-page--intro": layoutVariant === "intro",
							[css.pageIFrame]: isIframe
						})}
					>
						{!isIframe && <Header isLandingpage={isLandingpage} />}
						{!isIframe && hasAnimation && <Animation isLandingpage={isLandingpage} />}
						{!isIframe && <PageTransition color={primaryMainColor} position="top" />}
						{layoutTag === "default" && <Default />}
						{!isIframe && currentLocation && (
							<Footer isLandingpage={isLandingpage} lang={lang} />
						)}
					</div>
					<ReactMetaData01
						canonical={metaCanonical}
						description={metaDescription}
						index={metaIndexable}
						image={metaImage}
						keywords={metaKeywords}
						lat={metaLat}
						lang={lang}
						links={metaLinks}
						lng={metaLng}
						title={metaTitle}
					/>
					<Tracking />
				</Locals>
			</AccountData>
		</WebProfile>
	);
};

export default Views;
