import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import checkIFrame from "../utils/checkIFrame";

const useIFramePostHeight = () => {
	const location = useLocation();
	const isIframe = checkIFrame();

	useEffect(() => {
		if (!isIframe) return;

		document.body.classList.add("iframe");

		const postHeight = () => {
			window.parent.postMessage(
				{
					height: document.body.scrollHeight
				},
				"*"
			);
		};

		window.addEventListener("resize", () => {
			postHeight();
		});

		postHeight();

		const timeoutId = setTimeout(postHeight, 500);

		return () => {
			window.removeEventListener("resize", () => {
				postHeight();
			});
			clearTimeout(timeoutId);
		};
	}, [isIframe, location]);
};

export default useIFramePostHeight;
