import loadable from "@loadable/component";
import clsx from "clsx";
import Specifications from "./Specifications";

const BloggerDescription = loadable(() =>
	import("@micado-digital/react-blog/BloggerDescription")
);
const BlogList = loadable(() => import("@micado-digital/react-blog/views/List01"));
const BlogShortInfo = loadable(() => import("@micado-digital/react-blog/BlogShortInfo"));
const BlogTags = loadable(() => import("@micado-digital/react-blog/ListTags"));
const BlogTeaserGroup = loadable(() => import("@micado-digital/react-blog/BlogTeaserGroup"));
const Button = loadable(() => import("@material-ui/core/Button"));
const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const Container = loadable(() => import("@Components/views/Container"));
const FAQ = loadable(() => import("@Components/Elements/FAQ"));
const Form = loadable(() => import("@micado-digital/react-form"));
const Icons = loadable(() => import("@Components/Elements/Icons"));
const ListBlogger = loadable(() => import("@micado-digital/react-blog/ListBlogger"));
const Logos = loadable(() => import("@Components/Elements/Logos"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactGallery02 = loadable(() => import("@micado-digital/react-gallery/ReactGallery02"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactImageText03 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText03")
);
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactReferences01 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences01")
);
const ReactShare = loadable(() => import("@micado-digital/react-share/ReactShare"));
const ReactSingleImage01 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage01")
);
const ReactSingleImage04 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage04")
);
const ReactSingleTeaser03 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser03")
);
const ReactStoreLocator = loadable(() =>
	import("@micado-digital/react-store-locator/ReactStoreLocator")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactTeaserList02 = loadable(() =>
	import("@micado-digital/react-teaser-list/ReactTeaserList02")
);
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactLegacy = loadable(() => import("@micado-digital/react-legacy/ReactLegacy"));
const HeadlineTextCombo = loadable(() => import("@Components/Elements/HeadlineTextCombo"));
const BackgroundWrapper = loadable(() => import("@Components/Elements/BackgroundWrapper"));
const TeaserGroupGrid = loadable(() => import("@Components/Elements/TeaserGroup/Grid"));
const TeaserGroupSlider = loadable(() => import("@Components/Elements/TeaserGroup/Slider"));
const TeaserSlider = loadable(() => import("@Components/Elements/TeaserSlider"));
const TeaserTimeline = loadable(() => import("@Components/Elements/TeaserTimeline"));
const Reviews = loadable(() => import("@Components/Elements/Reviews"));
const ReactSearchResults = loadable(() =>
	import("@micado-digital/react-search-results/ReactSearchResults")
);

const getJSXElement = (element, lang, l, isMobile, sysLink, blogData) => {
	const { REACT_APP_PATH } = process.env;
	const { anchor, id, tag } = element;

	switch (tag) {
		case "basic-headline": {
			const { text, variant = 0 } = element;

			let config = {};

			switch (variant) {
				case 0: {
					config = {
						component: "h1",
						variants: {
							xs: "display-small",
							md: "display-large"
						}
					};
					break;
				}
				case 1: {
					config = {
						component: "h2",
						variants: {
							xs: "headline-medium",
							md: "headline-large"
						}
					};
					break;
				}
				case 2: {
					config = {
						component: "h3",
						variants: {
							xs: "headline-small",
							md: "headline-medium"
						}
					};
					break;
				}
				case 3: {
					config = {
						component: "h4",
						variant: "headline-small"
					};
					break;
				}
				case 4: {
					config = {
						component: "h5",
						variant: "title-large"
					};
					break;
				}
				case 5: {
					config = {
						component: "h6",
						variant: "title-medium"
					};
					break;
				}
				case 6: {
					config = {
						component: "p",
						variant: "title-small"
					};
					break;
				}
				case 7: {
					config = {
						component: "h1",
						variants: {
							xs: "display-small",
							md: "display-large"
						}
					};
					break;
				}
				default: {
					break;
				}
			}

			return (
				<Container
					anchor={anchor}
					className={`mco-view-component-container-basic-headline--variant-${variant}`}
					key={id}
					tag={tag}
				>
					<ReactHeadline01
						component={config?.component}
						text={text}
						variant={config?.variant}
						variants={config?.variants}
					/>
				</Container>
			);
		}

		case "headline-text-combo": {
			const { elements, id, textcontent: { items = [] } = {} } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1456} tag={tag}>
					<HeadlineTextCombo elements={elements} items={items} lang={lang} />
				</Container>
			);
		}

		case "basic-text": {
			const { text, variant } = element;

			return (
				<Container
					anchor={anchor}
					className={clsx({
						"mco-view-component-container-basic-text-variant-1": variant === 1
					})}
					key={id}
					tag={tag}
				>
					<ReactText01
						ButtonProps={{
							endIcon: <ReactSVGIcon src="/img/icons/chevron-down.svg" size={20} />,
							variant: "text"
						}}
						hideMobile={true}
						lang={lang}
						text={text}
						variant="body-medium"
					/>
				</Container>
			);
		}

		case "basic-list": {
			const { textcontent, title, variant } = element;

			return (
				<Container
					anchor={anchor}
					background
					className={`mco-view-component-container-basic-list-variant--${variant}`}
					maxWidth="none"
					noPadding
					key={id}
					tag={tag}
				>
					<Container>
						<ReactList01
							headlineVariants={{ xs: "headline-medium", md: "headline-large" }}
							icon={<ReactSVGIcon color="primary" src="/img/icons/check.svg" size={24} />}
							textcontent={textcontent}
							textVariant="body-medium"
							title={title}
							variant={variant === 2 ? 1 : variant}
						/>
					</Container>
				</Container>
			);
		}

		case "basic-table": {
			const { addition, title, textcontent, variant } = element;

			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactTable01
						addition={addition}
						headlineVariant="headline-large"
						items={newTableArray}
						title={title}
						sticky={variant === 1 ? true : false}
					/>
				</Container>
			);
		}

		case "basic-links": {
			const { elements, title } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactReferences01
						elements={elements}
						headlineVariants={{
							xs: "headline-medium",
							md: "headline-large"
						}}
						linkVariant="body-medium"
						title={title}
					/>
				</Container>
			);
		}

		case "basic-video": {
			const { media, title, text } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactVideo02
						additionVariant="body-small"
						titleVariants={{ xs: "headline-medium", md: "headline-large" }}
						media={media[0]}
						mediaFormats={{
							xs: "video-mobile",
							sm: "video"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			const { text } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactHTML key={id} html={text} />
				</Container>
			);
		}

		case "basic-download-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					{children?.map(item => getJSXElement(item, lang, l, isMobile, sysLink, blogData))}
				</Container>
			);
		}

		case "basic-download": {
			const { media, title } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-imprint": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactLegacy lang={lang} logoIdentifier="logo" />
				</Container>
			);
		}

		case "extension-background-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth="none" noPadding tag={tag}>
					<BackgroundWrapper>
						<>
							{children
								?.filter(item => item.tag !== "extension-background")
								?.map(item => getJSXElement(item, lang, l, isMobile, sysLink, blogData))}
						</>
					</BackgroundWrapper>
				</Container>
			);
		}

		case "basic-dropdown-group": {
			const { children } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactDropDown01
						icon={<ReactSVGIcon color="primary" src="/img/icons/dropdown.svg" size={24} />}
						option={0}
						title={element?.children?.[0]?.title}
					>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item, lang, l, isMobile, sysLink, blogData))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleimage": {
			const { addition, media, option2, reference, title, text, variant } = element;

			if (variant === 1) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						<ReactSingleImage01
							addition={addition}
							media={media ? media?.[0] : {}}
							mediaFormats={{
								xs: "singleimage-small-mobile",
								sm: "singleimage-small"
							}}
							mediaPath={REACT_APP_PATH}
							option2={option2}
							reference={reference}
							text={text}
							textVariant="body-small"
						/>
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} maxWidth={2560} noPadding tag={tag}>
					<ReactSingleImage04
						addition={addition}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "singleimage-mobile",
							sm: "singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						option2={option2}
						reference={reference}
						text={text}
						title={title}
						titleVariants={{ xs: "headline-small", md: "headline-large" }}
					/>
				</Container>
			);
		}

		case "basic-teaser": {
			const {
				addition2: externalURL,
				id,
				teasergroup: { items = [] } = {},
				reference: { pageName } = {},
				option4: target = 0,
				text: linkTitle = "",
				title,
				variant = 0
			} = element;

			// target 0 = "_top", target 1 = "_blank"

			let link = "";

			if (externalURL) {
				link = externalURL;
			} else if (pageName) {
				link = pageName;
			}

			const targetBlank = target === 1 ? true : false;

			if (variant === 0) {
				return (
					<Container anchor={anchor} key={id} tag={tag}>
						{isMobile && items?.length > 2 ? (
							<TeaserGroupSlider
								buttonLabel={l("teaser.button")}
								items={items}
								lang={lang}
								link={link}
								linkTitle={linkTitle}
								mobileSlider
								mediaFormats={{ xs: "teaser-mobile" }}
								targetBlank={targetBlank}
								title={title}
							/>
						) : (
							<TeaserGroupGrid
								buttonLabel={l("teaser.button")}
								items={items}
								lang={lang}
								link={link}
								linkTitle={linkTitle}
								mobileSlider
								mediaFormats={{ xs: "teaser-mobile", md: "teaser-sub" }}
								targetBlank={targetBlank}
								title={title}
							/>
						)}
					</Container>
				);
			}

			if (variant === 1) {
				return (
					<Container
						anchor={anchor}
						key={id}
						maxWidth="none"
						tag={tag}
						noPadding
						background
						style={{ overflow: "hidden" }}
					>
						<Container maxWidth="none">
							<TeaserGroupSlider
								buttonLabel={l("teaser.button")}
								items={items}
								lang={lang}
								link={link}
								linkTitle={linkTitle}
								mobileSlider
								mediaFormats={{ xs: "teaser-mobile" }}
								targetBlank={targetBlank}
								title={title}
								variant={variant}
							/>
						</Container>
					</Container>
				);
			}

			if (variant === 2) {
				return (
					<Container anchor={anchor} key={id} maxWidth="none" noPadding tag={tag}>
						<TeaserTimeline items={items} lang={lang} title={title} />
					</Container>
				);
			}

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactTeaserList02 headline={title} headlineVariant="headline-large">
						{items?.map((item, index) => {
							const variant = index % 2;

							return (
								<ReactSingleTeaser03
									buttonColor="primary"
									headlineVariants={{
										xs: "headline-large",
										md: "display-medium"
									}}
									key={index}
									media={item?.media?.[0]}
									mediaPath={REACT_APP_PATH}
									mediaFormats={{
										xs: "teaser-mobile",
										md: "teaser"
									}}
									teaser={item}
									textVariant="body-medium"
									variant={variant}
								/>
							);
						})}
					</ReactTeaserList02>
				</Container>
			);
		}

		case "basic-singleteaser": {
			const { id, teaser, text, title, variant, teaser: { media } = {} } = element;
			const teaserData = {
				id: id,
				link: teaser?.link,
				media: teaser?.media,
				text: text ? text : teaser?.text,
				title: title ? title : teaser?.title
			};

			return (
				<Container anchor={anchor} key={id} maxWidth={1440} noPadding tag={tag}>
					<ReactSingleTeaser03
						buttonColor="primary"
						buttonLabel={l("teaser.button")}
						buttonVariant="text"
						buttonSize="small"
						headlineVariants={{
							xs: "headline-small",
							md: "headline-large"
						}}
						icon="/img/icons/chevron-right.svg"
						iconSize={16}
						teaser={teaserData}
						media={media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser-mobile",
							md: "teaser"
						}}
						textVariant="body-medium"
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			const { addition2, media, option, text, title } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1440} noPadding tag={tag}>
					<ReactImageText03
						addition={title}
						additionVariant="body-small"
						headlineVariants={{
							xs: "headline-small",
							md: "headline-large"
						}}
						media={media?.[0]}
						mediaFormats={{
							xs: "imagetext-combi-mobile",
							md: "imagetext-combi"
						}}
						mediaPath={REACT_APP_PATH}
						title={addition2}
						text={text}
						textVariant="body-medium"
						variant={option}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			const { elements } = element;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactGallery02
						elements={elements}
						navNext="/img/icons/chevron-right-48.svg"
						navPrev="/img/icons/chevron-left-48.svg"
						mediaFormats={{
							xs: "gallery-mobile",
							sm: "gallery-single"
						}}
						overlayMediaFormats={{
							xs: "gallery"
						}}
						showOverlay={true}
						thumbs="gallery-preview"
					/>
				</Container>
			);
		}

		case "basic-form": {
			const { elements } = element;

			// PraKom API Form

			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);

			const name = urlParams.get("name");
			const enquiryGUID = urlParams.get("enquiry-guid");

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST">
						<Connector
							additionalFormElements={[
								{
									tag: "basic-form-hidden-name",
									component: <input type="hidden" name="name" value={name} />
								},
								{
									tag: "basic-form-hidden-enquiry-guid",
									component: <input type="hidden" name="enquiry-guid" value={enquiryGUID} />
								}
							]}
							formElements={elements}
							formID={id}
						/>
					</Form>
				</Container>
			);
		}

		case "extension-table-ext": {
			const { elements, id, textcontent: { items = [] } = {} } = element;

			const headline = items?.find(item => item?.name === "valHeadline")?.text;

			return (
				<Container anchor={anchor} background key={id} maxWidth="none" tag={tag} noPadding>
					<Container>
						<Specifications elements={elements} headline={headline} lang={lang} />
					</Container>
				</Container>
			);
		}

		case "extension-reviews": {
			const { elements, id, textcontent: { items = [] } = {} } = element;

			const headline = items?.find(item => item?.name === "valHeadline")?.text;

			return (
				<Container anchor={anchor} key={id} maxWidth="none" tag={tag} noPadding>
					<Reviews elements={elements} headline={headline} lang={lang} />
				</Container>
			);
		}

		case "extension-search-result": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactSearchResults
						api={`${REACT_APP_PATH}/searchv2.json.api`}
						detailUrl={sysLink("search")}
						icon={
							<ReactSVGIcon
								src={REACT_APP_PATH + "/img/icons/chevron-right-24.svg"}
								size={24}
							/>
						}
						lang={lang}
					/>
				</Container>
			);
		}

		case "extension-faq": {
			const { textcontent: { items } = {} } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth="none" noPadding tag={tag}>
					<FAQ anchor={anchor} content={items} />
				</Container>
			);
		}

		case "extension-teaser-slider": {
			const { elements, textcontent: { items = [] } = {} } = element;

			const headline = items?.find(({ name }) => name === "valHeadline")?.text;

			return (
				<Container anchor={anchor} key={id} maxWidth="none" noPadding tag={tag}>
					<TeaserSlider headline={headline} items={elements} lang={lang} />
				</Container>
			);
		}

		case "extension-logos": {
			const { textcontent: { items = [] } = {} } = element;

			const folder = items?.find(({ name }) => name === "valFolder")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<Logos folder={folder} />
				</Container>
			);
		}

		case "extension-icons": {
			const { elements = [] } = element;

			return (
				<Container anchor={anchor} key={id} maxWidth={1456} tag={tag}>
					<Icons elements={elements} />
				</Container>
			);
		}

		case "extension-store-locator": {
			const { id, textcontent: { items = [] } = {} } = element;

			const headline = items?.find(item => item?.name === "valHeadline")?.text;

			return (
				<Container anchor={anchor} key={id} maxWidth="none" tag={tag} noPadding>
					<ReactStoreLocator
						buttonLabel={l("storeLocator.button")}
						buttonLink={sysLink("store-locator-request")}
						fallbackImageIdentifier="fallback-store-locator"
						headline={headline}
						lang={lang}
						headlineVariant="headline-large"
						mediaFormats={{ xs: "address" }}
					/>
				</Container>
			);
		}

		case "mco-blog-list": {
			if (!element?.textcontent) {
				return;
			}

			const {
				textcontent: { items }
			} = element;

			let categories = items?.find(({ name }) => name === "valCategories")?.text;
			let keywords = items?.find(({ name }) => name === "valKeywords")?.text;
			let author = items?.find(({ name }) => name === "valAuthor")?.text;

			if (categories) categories = categories?.replaceAll("|", ",");
			if (keywords) keywords = keywords?.replaceAll("|", ",");

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogList
						authorID={author}
						categories={categories ?? ""}
						defaultLang={lang}
						keywords={keywords ?? ""}
						mediaFormats={{ xs: "teaser-sub" }}
						view="grid"
						PaginationProps={{ shape: "rounded", size: "large" }}
					/>
				</Container>
			);
		}

		case "mco-blog-teaser": {
			if (!element?.textcontent) {
				return;
			}

			const {
				textcontent: { items },
				elements
			} = element;

			let categories = items?.find(({ name }) => name === "valCategories")?.text;
			let keywords = items?.find(({ name }) => name === "valKeywords")?.text;
			let author = items?.find(({ name }) => name === "valAuthor")?.text;
			let selected = items?.find(({ name }) => name === "valSelected")?.text;
			let headline = items?.find(({ name }) => name === "valHeadline")?.text;
			let url = elements?.find(item => item?.tag === "valURL")?.reference?.pageName;
			let buttonLabel = items?.find(({ name }) => name === "valButton")?.text;

			if (categories) categories = categories?.replaceAll("|", ",");
			if (keywords) keywords = keywords?.replaceAll("|", ",");
			if (selected) selected = selected?.replaceAll("|", ",");

			const params = {
				authorID: author,
				categories: categories,
				excluded: blogData?.id,
				keywords: keywords,
				selected: selected
			};

			const detailButton = (
				<Button
					color="primary"
					endIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={24} />}
					size="medium"
					variant="text"
				>
					{buttonLabel ? buttonLabel : l("blog.buttonAll")}
				</Button>
			);

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogTeaserGroup
						detailButton={detailButton}
						detailURL={url}
						headline={headline}
						headlineVariant="headline-large"
						lang={lang}
						mediaFormats={{ xs: "teaser-sub" }}
						id={1}
						params={params}
						SwiperProps={{
							breakpoints: {
								0: {
									slidesPerView: 1.1,
									spaceBetween: 16
								},
								600: {
									slidesPerView: 2.1,
									spaceBetween: 16
								},
								960: {
									slidesPerView: 3,
									spaceBetween: 24
								},
								1280: {
									slidesPerView: 3.5,
									spaceBetween: 24
								}
							},
							delay: 3000,
							speed: 800
						}}
					/>
				</Container>
			);
		}

		case "mco-blog-detail-info": {
			const date = blogData?.editDate ? new Date(blogData?.editDate) : null;
			const dateOptions = { day: "2-digit", month: "long", year: "numeric" };
			const dateStr = date?.toLocaleDateString(undefined, dateOptions);

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogShortInfo authorID={blogData?.authorID} blogDate={dateStr} lang={lang} />
					<ReactShare
						buttonProps={{
							icon: <ReactSVGIcon src="/img/icons/share.svg" size={20} />,
							size: "small"
						}}
						customIcons={true}
						iconSize={24}
						lang={lang}
						shareOptions={{
							email: true,
							facebook: true,
							linkedIn: true,
							pinterest: true,
							twitter: true,
							whatsapp: true
						}}
					/>
				</Container>
			);
		}

		case "mco-blog-list-blogger": {
			const { textcontent: { items } = {}, variant } = element;

			let headline = items?.find(({ name }) => name === "valHeadline")?.text;
			let selected = items?.find(({ name }) => name === "valSelected")?.text;

			if (selected) selected = selected?.replaceAll("|", ",");

			return (
				<Container
					anchor={anchor}
					key={id}
					tag={tag}
					variant={variant === 1 ? "background" : ""}
				>
					<ListBlogger
						headline={headline}
						lang={lang}
						headlineVariant="headline-large"
						selected={selected}
					/>
				</Container>
			);
		}

		case "mco-blog-blogger-desc": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BloggerDescription authorID={blogData?.authorID} lang={lang} />
				</Container>
			);
		}

		case "mco-blog-share": {
			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<ReactShare
						buttonProps={{
							icon: <ReactSVGIcon src="/img/icons/share.svg" size={20} />,
							size: "small"
						}}
						customIcons={true}
						iconSize={24}
						lang={lang}
						shareOptions={{
							email: true,
							facebook: true,
							linkedIn: true,
							pinterest: true,
							twitter: true,
							whatsapp: true
						}}
					/>
				</Container>
			);
		}

		case "mco-blog-tags": {
			const {
				textcontent: { items }
			} = element;

			let headline = items?.find(({ name }) => name === "valHeadline")?.text;

			return (
				<Container anchor={anchor} key={id} tag={tag}>
					<BlogTags
						headline={headline}
						headlineVariant="label-small"
						labelVariant="label-small"
					/>
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
