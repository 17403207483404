import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logo: {
		display: "flex",
		position: "relative",
		zIndex: 110
	},
	link: {
		display: "block",
		padding: theme.spacing(2, 0)
	},
	image: {
		display: "block",
		height: 28,
		width: 140,
		[theme.breakpoints.up("sm")]: {
			height: 30,
			width: 150
		}
	},
	imageIntro: {
		height: 28,
		width: 140,
		[theme.breakpoints.up("sm")]: {
			height: 47,
			width: 150
		},
		[theme.breakpoints.up("lg")]: {
			height: 80,
			width: 253
		}
	}
}));

export default styles;
